import React, { useState } from "react";
import Navbar from "../components/Navbar";
import PricingSection from "../components/PricingSection";

const LandingPage: React.FC = () => {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState<{
    type: "success" | "error";
    text: string;
  } | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setMessage(null);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/early-access/signup`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || "Failed to sign up");
      }

      setMessage({
        type: "success",
        text: "Thanks for signing up! We'll keep you updated.",
      });
      setEmail("");
    } catch (error) {
      setMessage({
        type: "error",
        text: error instanceof Error ? error.message : "Failed to sign up",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-main-black relative overflow-hidden">
      <Navbar />
      <div
        className="background-circle"
        style={{ top: "20%", left: "10%" }}
      ></div>
      <div
        className="background-circle"
        style={{ top: "50%", right: "15%" }}
      ></div>
      <div
        className="background-circle"
        style={{ bottom: "20%", left: "30%" }}
      ></div>
      <div
        className="background-circle"
        style={{ bottom: "-10%", right: "25%" }}
      ></div>
      <div
        className="background-circle"
        style={{ bottom: "-15%", left: "15%" }}
      ></div>
      <main className="max-w-[2000px] mx-auto px-6 relative z-10">
        <div className="mt-8 md:mt-12 mb-16 text-center">
          <h1 className="text-5xl md:text-7xl font-bold leading-tight text-soft-cloud">
            Upload once to{" "}
            <span className="block md:inline">your every account</span>
          </h1>
          <p className="text-lg md:text-xl mt-6 md:mt-8 text-synced-blue max-w-2xl mx-auto">
            Upload your videos or podcasts to all, or any of your channels with
            a single click.
          </p>
        </div>
        <div className="space-y-12">
          {/* Upload Section */}
          <div className="flex items-center">
            <img
              src="/illo_one.png"
              alt="Upload"
              className="w-1/2 rounded-lg pointer-events-none"
            />
            <div className="text-soft-cloud ml-8 flex-1">
              <h2 className="text-3xl font-bold mb-4 text-cadet-gray">
                Upload
              </h2>
              <p>Upload your content easily and efficiently.</p>
            </div>
          </div>

          {/* Analytics Section */}
          <div className="flex items-center">
            <div className="text-soft-cloud text-right mr-8 flex-1">
              <h2 className="text-3xl font-bold mb-4 text-cadet-gray">
                Analytics
              </h2>
              <p>Track your performance with detailed analytics.</p>
            </div>
            <img
              src="/analytics.png"
              alt="Analytics"
              className="w-1/2 rounded-lg shadow-lg pointer-events-none"
            />
          </div>

          {/* Dashboard Section */}
          <div className="flex items-center">
            <img
              src="/dashboard.jpeg"
              alt="Dashboard"
              className="w-1/2 rounded-lg shadow-lg pointer-events-none"
            />
            <div className="text-soft-cloud ml-8 flex-1">
              <h2 className="text-3xl font-bold mb-4 text-cadet-gray">
                Dashboard
              </h2>
              <p>Manage your content with our intuitive dashboard.</p>
            </div>
          </div>
        </div>

        {/* Sign Up Section */}
        <div className="mt-24 text-center">
          <div className="flex flex-col md:flex-row justify-center items-center space-y-2 md:space-y-0 md:space-x-6">
            <h2 className="text-2xl font-bold text-soft-cloud mb-2 md:mb-0">
              Sign up for early access
            </h2>
            <form
              onSubmit={handleSubmit}
              className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4"
            >
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter e-mail address"
                className="px-4 py-2 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-synced-blue"
                disabled={isSubmitting}
                required
              />
              <button
                type="submit"
                disabled={isSubmitting}
                className={`whitespace-nowrap px-6 py-2 bg-yellow-500 text-black rounded-full 
                  hover:bg-yellow-600 focus:outline-none transition-colors
                  ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""}`}
              >
                {isSubmitting ? "Submitting..." : "Keep me posted"}
              </button>
            </form>
            {message && (
              <div
                className={`mt-2 text-sm ${
                  message.type === "success" ? "text-green-500" : "text-red-500"
                }`}
              >
                {message.text}
              </div>
            )}
          </div>
        </div>

        {/* Pricing Section */}
        <PricingSection />
        <div className="pb-24"></div>
      </main>
    </div>
  );
};

export default LandingPage;
