import React, { useState } from "react";
import { Link } from "react-router-dom";
import { LogIn, UserPlus, Menu, X } from "lucide-react";

const APP_URL = process.env.REACT_APP_APP_URL;

const Navbar: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <nav className="bg-main-black px-6 py-8 w-full relative">
      <div className="max-w-[2000px] mx-auto flex items-center justify-between">
        {/* Logo and Brand */}
        <div className="flex items-center flex-shrink-0">
          <Link to="/" className="flex items-center">
            <img
              src="/logo.svg"
              alt="SubSynced"
              className="h-12 w-12 md:h-16 md:w-16"
            />
            <span className="text-3xl md:text-4xl font-bold ml-4">
              <span className="text-sub-blue">Sub</span>
              <span className="text-synced-blue">Synced</span>
            </span>
          </Link>
        </div>

        <div className="flex items-center">
          {/* Auth Buttons */}
          <div className="hidden sm:flex items-center space-x-4 flex-shrink-0 mr-4">
            <a
              href={`${APP_URL}/login`}
              className="flex items-center space-x-2 text-synced-blue hover:text-soft-cloud text-lg whitespace-nowrap min-w-[100px] justify-center"
            >
              <LogIn className="w-6 h-6" />
              <span>Sign in</span>
            </a>
            <a
              href={`${APP_URL}/register`}
              className="flex items-center space-x-2 bg-button-sand text-midnight-coal px-6 py-2 rounded-full hover:bg-cadet-gray transition-colors duration-200 text-lg whitespace-nowrap min-w-[120px] justify-center"
            >
              <UserPlus className="w-6 h-6" />
              <span>Sign up</span>
            </a>
          </div>

          {/* Mobile Menu Button */}
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="nav:hidden text-synced-blue hover:text-soft-cloud"
          >
            {isMenuOpen ? <X size={32} /> : <Menu size={32} />}
          </button>
        </div>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="xl:hidden absolute top-full left-0 right-0 bg-main-black p-6 border-t border-synced-blue/20">
            <div className="md:hidden flex flex-col space-y-4 pt-4">
              <a
                href={`${APP_URL}/login`}
                className="flex items-center space-x-2 text-synced-blue hover:text-soft-cloud text-lg whitespace-nowrap"
              >
                <LogIn className="w-6 h-6" />
                <span>Sign in</span>
              </a>
              <a
                href={`${APP_URL}/register`}
                className="flex items-center space-x-2 bg-button-sand text-midnight-coal px-6 py-2 rounded-full hover:bg-cadet-gray transition-colors duration-200 text-lg whitespace-nowrap w-fit"
              >
                <UserPlus className="w-6 h-6" />
                <span>Sign up</span>
              </a>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
