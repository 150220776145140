import React, { useState, useRef, useEffect } from "react";
import {
  FaHeart,
  FaCrown,
  FaBolt,
  FaCheckCircle,
  FaTimesCircle,
} from "react-icons/fa";
import { useMediaQuery } from "react-responsive";

const plans = [
  {
    name: "Free",
    price: 0,
    description: "Perfect plan to get started",
    subdescription: "A free plan grants you access to some cool features.",
    features: [
      { text: "Upload once to 3 accounts", included: true },
      { text: "Manage your videos", included: true },
      { text: "All platforms simultaneously", included: false },
      { text: "Comments", included: false },
      { text: "Analytics", included: false },
    ],
    icon: <FaHeart className="w-6 h-6 text-[#5B7B9F] mr-2" />,
    buttonText: "Get Your Free Plan",
  },
  {
    name: "Professional",
    price: 52,
    description: "Perfect plan for professionals!",
    subdescription: "Perfect plan for content creators and professionals.",
    features: [
      { text: "Everything in Free Plan", included: true },
      { text: "All platforms simultaneously", included: true },
      { text: "Comments", included: true },
      { text: "Analytics", included: true },
      { text: "AI Analyses", included: false },
      { text: "Advanced suggestions", included: false },
      { text: "Storage & crisis management", included: false },
    ],
    icon: <FaCrown className="w-6 h-6 text-[#5B7B9F] mr-2" />,
    buttonText: "Get Started",
  },
  {
    name: "Enterprise",
    price: 121,
    description: "Best suits for great company!",
    subdescription: "Big creator or big ambitions? We've got you covered.",
    features: [
      { text: "Everything in Pro Plan", included: true },
      { text: "AI Analyses", included: true },
      { text: "Advanced suggestions", included: true },
      { text: "Storage & crisis management", included: true },
    ],
    icon: <FaBolt className="w-6 h-6 text-[#5B7B9F] mr-2" />,
    buttonText: "Get Started",
  },
];

const calculateYearlyPrice = (monthlyPrice: number) => {
  const yearlyPrice = monthlyPrice * 12;
  const discount = 0.65;
  const discountedPrice = yearlyPrice * (1 - discount);
  return Math.ceil(discountedPrice);
};

const PricingSection: React.FC = () => {
  const [isYearly, setIsYearly] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const mobileContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isMobile && mobileContainerRef.current) {
      const container = mobileContainerRef.current;
      const cardWidth = 300;
      const gap = 16;
      const scrollPosition = cardWidth + gap;
      container.scrollLeft = scrollPosition;
    }
  }, [isMobile]);

  const handleToggle = () => {
    setIsYearly(!isYearly);
  };

  return (
    <div className="mt-16 py-8">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center text-soft-cloud mb-4">
          Ready to Get Started?
        </h2>
        <p className="text-center text-synced-blue mb-6">
          Choose a plan that suits your business needs
        </p>
        <div className="flex flex-col items-center mb-6">
          <div className="flex items-center">
            <span className="text-white mr-4">Monthly</span>
            <div
              onClick={handleToggle}
              className={`w-12 h-6 flex items-center ${
                isYearly ? "bg-yellow-500" : "bg-gray-500"
              } rounded-full p-1 cursor-pointer transition-colors duration-300`}
            >
              <div
                className={`bg-main-black w-4 h-4 rounded-full shadow-md transform ${
                  isYearly ? "translate-x-6" : "translate-x-0"
                } transition-transform duration-300`}
              ></div>
            </div>
            <span className="text-white ml-4">Yearly</span>
          </div>
          <img
            src="/discount.png"
            alt="Save 65%"
            className="mt-2 ml-8 w-40 pointer-events-none"
          />
        </div>

        <div className="hidden md:flex flex-row justify-center items-stretch relative max-w-7xl mx-auto md:space-x-[-20px] overflow-visible">
          {plans.map((plan, index) => (
            <div
              key={plan.name}
              className={`px-8 py-6 w-[340px] flex flex-col justify-between rounded-lg ${
                index === 1
                  ? "transform scale-105 z-20 bg-[#E5E7EB] shadow-[0_0_20px_rgba(0,0,0,0.2)]"
                  : "z-10 bg-[#DBDCE0] shadow-[0_0_15px_rgba(0,0,0,0.15)]"
              }`}
            >
              <div className="mx-4">
                <div className="flex items-center mb-4">
                  {plan.icon}
                  <h3 className="text-xl font-bold">{plan.name}</h3>
                </div>
                <p className="text-gray-600 mb-4 ">{plan.description}</p>
                <p className="text-2xl font-bold mb-4">
                  ${isYearly ? calculateYearlyPrice(plan.price) : plan.price}{" "}
                  <span className="text-sm">
                    {isYearly ? "/year" : "/month"}
                  </span>
                </p>
                <p className="text-gray-600 mb-4 h-[72px]">
                  {plan.subdescription}
                </p>
                <ul className="mb-6 space-y-3">
                  {plan.features.map((feature) => (
                    <li key={feature.text} className="flex items-center">
                      {feature.included ? (
                        <FaCheckCircle className="w-5 h-5 text-green-600 mr-2 flex-shrink-0" />
                      ) : (
                        <FaTimesCircle className="w-5 h-5 text-gray-500 mr-2 flex-shrink-0" />
                      )}
                      <span>{feature.text}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <button className="w-full py-2 bg-yellow-500 text-black rounded-full hover:bg-yellow-600">
                {plan.buttonText}
              </button>
            </div>
          ))}
        </div>

        <div className="md:hidden w-full">
          <div
            ref={mobileContainerRef}
            className="overflow-x-auto overflow-y-hidden scrollbar-hide snap-x snap-mandatory scroll-smooth"
          >
            <div className="flex flex-row gap-4 px-4">
              <div className="w-[20px] flex-shrink-0" />
              {plans.map((plan, index) => (
                <div
                  key={plan.name}
                  className="w-[300px] flex-shrink-0 px-8 py-6 flex flex-col justify-between rounded-lg snap-center bg-[#E5E7EB] shadow-[0_0_15px_rgba(0,0,0,0.15)]"
                >
                  <div className="mx-4">
                    <div className="flex items-center mb-4">
                      {plan.icon}
                      <h3 className="text-xl font-bold">{plan.name}</h3>
                    </div>
                    <p className="text-gray-600 mb-4 h-[48px]">
                      {plan.description}
                    </p>
                    <p className="text-2xl font-bold mb-4">
                      $
                      {isYearly ? calculateYearlyPrice(plan.price) : plan.price}{" "}
                      <span className="text-sm">
                        {isYearly ? "/year" : "/month"}
                      </span>
                    </p>
                    <p className="text-gray-600 mb-4 h-[72px]">
                      {plan.subdescription}
                    </p>
                    <ul className="mb-6 space-y-3">
                      {plan.features.map((feature) => (
                        <li key={feature.text} className="flex items-center">
                          {feature.included ? (
                            <FaCheckCircle className="w-5 h-5 text-green-600 mr-2 flex-shrink-0" />
                          ) : (
                            <FaTimesCircle className="w-5 h-5 text-gray-500 mr-2 flex-shrink-0" />
                          )}
                          <span>{feature.text}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <button className="w-full py-2 bg-yellow-500 text-black rounded-full hover:bg-yellow-600">
                    {plan.buttonText}
                  </button>
                </div>
              ))}
              <div className="w-[20px] flex-shrink-0" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingSection;
